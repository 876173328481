.energy-settings {
  height: calc(100vh - 137px);
  width: 50%;
  margin: auto;
  @media screen and (max-width: 576px) {
    width: 100%;
    height: calc(100vh - 107px);
  }
  .ant-card {
    height: 100%;
  }
}
