@import "styles/definition/text.scss";
@import "styles/definition/variables.scss";

.energy-confirmation-signup {
  margin: 0 auto;
  padding: 20px;
  padding-top: 48px;
  background-color: white;
  min-height: calc(100vh - 88px);

  .energy-confirmation-signup__form {
    max-width: 600px;
    margin: auto;
    h1 {
      letter-spacing: -0.025em;
      margin-bottom: 32px;
      font-size: 48px;
      line-height: 56px;
      font-weight: 900;
      text-align: center;
    }

    .btn-connect {
      background-color: #1e6862ff;
      border-radius: 8px;
      height: 52px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      color: #fff;
      border-color: #1e6862ff !important;
      width: 100%;
      &:hover {
        background-color: rgb(35, 122, 115);
        color: #fff;
      }
    }

    #confirmation-signup-form {
      max-width: 392px;
      margin: auto;
      input {
        width: 100%;
        height: 60px;
        font-size: 20px;
      }
      .ant-otp {
        width: 100%;
      }
    }
  }
}
