.energy-app {
  min-height: 100vh;
  .ant-layout {
    min-height: 100vh;
    .ant-layout-content {
      overflow: auto;
      position: relative;
      @media screen and (max-width: 576px) {
        padding: 64px 12px 0 !important;
      }

      &.content--homepage {
        @media screen and (max-width: 576px) {
          padding: 0 !important;
        }
      }
    }
  }

  &.energy-app--outisde {
    .ant-layout-content {
      padding: 0 !important;
    }
  }
}

.ag-theme-quartz {
  height: 100%;
}

.ant-layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding-right: 24px !important;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  z-index: 9999;
  height: 88px;
  @media screen and (max-width: 576px) {
    height: 64px;
  }
  .logo {
    font-size: 22px;
    font-weight: 600;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    // padding: 12px 24px;
    &.collapsed {
      text-align: center;
    }
  }

  &.header--fixed {
    @media screen and (min-height: 1200px) {
      background-color: transparent !important;
      backdrop-filter: none !important;

      .btn-burger {
        color: #fff !important;
        &:hover {
          color: #fff !important;
        }
      }
      .logo {
        color: #fff !important;
      }
    }
  }

  &.header--white {
    // background-color: #fff !important;
    .btn-burger {
      color: #000 !important;
      &:hover {
        color: #000 !important;
      }
    }
    .logo {
      color: #000 !important;
    }
  }

  &.header--absolute-white {
    background-color: #fff !important;
    border-bottom: 1px solid rgb(211, 211, 211);
  }

  .btn-login {
    svg {
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &:hover {
      background-color: #3b8686 !important;
      color: #fff !important;
      border-color: #3b8686 !important;
      svg {
        fill: #fff !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 12px !important;
  }
}

.drawer-custom {
  z-index: 9999;
}

.ant-message {
  z-index: 9999;
}
