$black: #000;
$white: #fff;
$lime: #cff09e;
$light-emeraude: #a8dba8;
$emeraude: #79bd9a;
$bottle-green: #3b8686;
$blue: #0b486b;

$neutral-lightest: #eeeeee;
$neutral-lighter: #cccccc;
$neutral-light: #aaaaaa;
$neutral: #666666;
$neutral-dark: #444444;
$neutral-darker: #222222;
$neutral-darkest: #111111;

$success: #027a48;
$success-light: #ecfdf3;
$error: #b42318;
$error-light: #fef3f2;

// $info: #3683f0;
// $warning: #fab201;
// $error-hover: #fff3f2;

$bg-primary: $white;
$bg-secondary: $neutral-lightest;
$bg-tertiary: $neutral;
$bg-alternate: $black;
$bg-success: $success-light;
$bg-error: $error-light;

$border-primary: $black;
$border-secondary: $neutral-light;
$border-tertiary: $neutral-dark;
$border-alternate: $white;
$border-success: $success;
$border-error: $error;

$text-primary: $black;
$text-secondary: $neutral-light;
$text-alternate: $white;
$text-success: $success;
$text-error: $error;

$link-primary: $black;
$link-secondary: $neutral;
$link-alternate: $white;

$header-height: 48px;
$header-navigate: 46px;