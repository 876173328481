@import "styles/definition/variables.scss";
@import "styles/definition/text.scss";

.btn-primary {
  @extend .text-regular;
  background-color: $black;
  color: $white;
  border: 1px solid $black;
  border-radius: 12px;
  padding: 12px 24px;

  &.btn-primary--small {
    padding: 8px 20px;
  }
}

.btn-secondary {
  @extend .text-regular;
  background-color: $white;
  color: $black;
  border: 1px solid $black;
  border-radius: 12px;
  padding: 12px 24px;
  &.btn-secondary--small {
    padding: 8px 20px;
  }
}
