.energy-control {
  height: calc(100vh - 250px);
  @media screen and (max-width: 576px) {
    height: calc(100vh - 114px);
  }

  .ant-radio-group {
    display: flex;
    gap: 16px;
    .ant-radio-button-wrapper {
      border-radius: 100px;
      background-color: #eaddff;
      border-color: #eaddff;
      color: #fff;
      font-weight: 500;
      &::before {
        display: none;
      }

      &.ant-radio-button-wrapper-checked {
        background-color: #65558f;
        border-color: #65558f;
      }
    }
  }

  .ag-theme-quartz {
    .ag-row-selected::before {
      background-color: transparent;
    }
  }
}
