@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/common.scss";
@import "styles/definition/variables.scss";
@import "styles/definition/text.scss";
@import "styles/definition/button.scss";

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-spin-fullscreen {
  background-color: $white;
}

.ant-spin-lg .anticon {
  font-size: 40px;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
