@import "styles/definition/text.scss";
@import "styles/definition/variables.scss";

.energy-home__suggestion-renovation {
  .ant-tabs {
    .ant-tabs-nav-wrap {
      background-color: #f4f4f4;
      border-radius: 100px;
      padding: 4px;
      .ant-tabs-nav-list {
        width: 100%;
        .ant-tabs-tab {
          padding: 3px;
          margin: 0;
          flex: 1;
          justify-content: center;
          border-radius: 100px;
          @extend .text-regular;

          .ant-tabs-tab-btn {
            color: $black;
          }

          &.ant-tabs-tab-active {
            background-color: #dcdcdc;
            @extend .text-regular-bold;
          }
        }
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-nav {
      &::before {
        display: none;
      }
    }
  }

  .suggestion-item {
    padding: 8px 12px;
    border-radius: 20px;
    border: 1px solid #000000;
    &.active {
      border: 1px solid #3b8686;
      background: #3b86864d;
    }

    .icon-stat {
      path {
        fill: #0000004d;
      }
      &.icon-stat--active {
        path {
          fill: #000;
        }
      }
    }
  }

  .energy-home__montant {
    padding: 12px 20px;
    background: #eeeeee;
    border-radius: 30px;
    max-width: max-content;

    p {
      color: #414141;
    }

    .montant-label {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 40px;
    }
  }
}

.energy-home__graphe-price {
  margin-top: 30px;
  height: 340px;

  #graphe-price-chart {
    height: 100%;
  }
  .axis line,
  .axis path {
    stroke: #ddd;
  }
  .current-value-line {
    stroke: #79bd9a;
    stroke-width: 4;
    stroke-dasharray: 10, 5;
  }
  .gaussian {
    fill: #3b86861a;
    stroke: #3b8686;
    stroke-width: 3;
  }
  .annotation {
    fill: #000;
  }
}
