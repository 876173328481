h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 67.2px;
  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 48px;
  }
}

h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 57.6px;
  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 43.2px;
  }
}

h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 38.4px;
  }
}

h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 41.6px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 33.6px;
  }
}

h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 33.6px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
}

h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 25.2px;
  }
}

// Text large
.text-large {
  font-size: 20px;
  line-height: 30px;
}

.text-large-extra-bold {
  @extend .text-large;
  font-weight: 800;
}

.text-large-bold {
  @extend .text-large;
  font-weight: 700;
}

.text-large-semi-bold {
  @extend .text-large;
  font-weight: 600;
}

.text-large-medium {
  @extend .text-large;
  font-weight: 500;
}

// Text medium
.text-medium {
  font-size: 18px;
  line-height: 27px;
}

.text-medium-extra-bold {
  @extend .text-medium;
  font-weight: 800;
}

.text-medium-bold {
  @extend .text-medium;
  font-weight: 700;
}

.text-medium-semi-bold {
  @extend .text-medium;
  font-weight: 600;
}

.text-medium-medium {
  @extend .text-medium;
  font-weight: 500;
}

// Text regular
.text-regular {
  font-size: 16px;
  line-height: 24px;
}

.text-regular-extra-bold {
  @extend .text-regular;
  font-weight: 800;
}

.text-regular-bold {
  @extend .text-regular;
  font-weight: 700;
}

.text-regular-semi-bold {
  @extend .text-regular;
  font-weight: 600;
}

.text-regular-medium {
  @extend .text-regular;
  font-weight: 500;
}

// Text small
.text-small {
  font-size: 14px;
  line-height: 21px;
}

.text-small-extra-bold {
  @extend .text-small;
  font-weight: 800;
}

.text-small-bold {
  @extend .text-small;
  font-weight: 700;
}

.text-small-semi-bold {
  @extend .text-small;
  font-weight: 600;
}

.text-small-medium {
  @extend .text-small;
  font-weight: 500;
}

// Text tiny
.text-tiny {
  font-size: 12px;
  line-height: 18px;
}

.text-tiny-extra-bold {
  @extend .text-tiny;
  font-weight: 800;
}

.text-tiny-bold {
  @extend .text-tiny;
  font-weight: 700;
}

.text-tiny-semi-bold {
  @extend .text-tiny;
  font-weight: 600;
}

.text-tiny-medium {
  @extend .text-tiny;
  font-weight: 500;
}
