.homepage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  padding-top: 80px;

  .top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 8px 24px;
    z-index: 999;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  }
  .content {
    width: 65%;
    @media screen and (max-width: 1024px) {
      width: 90%;
    }
    @media screen and (max-width: 576px) {
      width: 100%;
    }

    .data-table {
      &.data-table__generalites {
        .ant-form-item-control-input {
          max-width: 250px;
          @media screen and (max-width: 576px) {
            max-width: 100%;
          }
        }
      }

      h2 {
        border: 2px solid #d9d9d9;
        padding: 4px 24px;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      .data-table__body {
        padding: 12px 120px;
        padding-top: 20px;
        border-bottom: 2px solid #d9d9d9;
        border-left: 2px solid #d9d9d9;
        border-right: 2px solid #d9d9d9;
        &.data-table__body--padding-small {
          padding: 20px 40px;
          & > .ant-select {
            margin: 0;
          }
        }
        @media screen and (max-width: 1440px) {
          padding: 12px 40px;
        }
        @media screen and (max-width: 576px) {
          padding: 12px 20px;
        }
      }
      .ant-form-item-label {
        span {
          color: #454545;
        }
        svg {
          fill: #454545;
        }
      }
      .ant-value {
        color: #00008b;
      }

      .row-data {
        display: flex;
        border-bottom: 2px solid #d9d9d9;
        border-left: 2px solid #d9d9d9;
        border-right: 2px solid #d9d9d9;

        .col-title {
          border-right: 2px solid #d9d9d9;
          padding: 12px;
          min-width: 180px;
          text-transform: capitalize;
          @media screen and (max-width: 1280px) {
            min-width: 120px;
          }
          @media screen and (max-width: 768px) {
            width: 70px;
            min-width: unset;
            flex-shrink: 0;
          }
        }

        .col-info {
          padding: 12px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          min-width: 500px;
          @media screen and (max-width: 576px) {
            min-width: unset;
            max-width: 80%;
            .ant-form-item {
              margin-bottom: 12px;
            }
          }

          .isolation {
            @media screen and (max-width: 576px) {
              .ant-col {
                max-width: unset;
                flex: unset;
                padding-bottom: 0;
              }

              .ant-form-item-row {
                gap: 12px;
                align-items: center;
              }
            }
          }
        }

        .select-envelope {
          .ant-select-selection-placeholder {
            color: #000;
          }
        }
      }
    }

    .ant-select.has-value {
      .ant-select-clear {
        opacity: 1;
      }
    }
  }
}
